<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="欠费记录"
    :width="950"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button @click="visible = false">取消</a-button>
    </template>

    <div style="padding: 4px 0">
      <a-descriptions layout="horizontal" bordered :column="2">
        <a-descriptions-item label="欠费金额合计：">{{
         money(oweRecordsVo.oweAmount)+ "元"
        }}</a-descriptions-item>
        <a-descriptions-item label="欠费订单数合计：">{{
          oweRecordsVo.oweOrderNumber
        }}</a-descriptions-item>
      </a-descriptions>
    </div>
    <div style="padding: 4px 2">
      <a-table
        row-key="id"
        table-layout="fixed"
        :data-source="items.oweRecords"
        :columns="columns"
        :loading="loading"
        :pagination="false"
        bordered
        >
          <template #parkingTime="{ text }">
              {{ getparkingTime(text) }}
          </template>
          <template #paidAmount="{ text }">
              {{ money(text) }}
          </template>
          <template #shouldPayAmount="{ text }">
              {{ money(text) }}
          </template>
          <template #oweAmount="{ text }">
              {{ money(text) }}
          </template>

      </a-table>
      <a-pagination
        v-model:current="page"
        v-model:page-size="pageSize"
        :total="total"
        :show-total="total => `${total}条数据`"
        @change="currentChange"
      >
      </a-pagination>
    </div>
  </a-modal>
</template>

<script>
import { ref, reactive, onMounted, toRefs } from "vue";
import useMessage from "@/hooks/useMessage";
import { detail } from "@/api/member/plateNumberApi";
import usePagination from "@/hooks/usePagination";

export default {
  name: "Detail",
  props: ["modalRef", "plateNumber"],
  emits: ["close"],
  setup(props, { emit }) {
    const disabled = ref(false);
    const items = reactive({ oweRecords: [] });
    const { showErr } = useMessage();
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const { page, pageSize, currentChange, doQuery, doRefresh } =
      usePagination(loadData);
    const oweRecordsVo = reactive({
      oweAmount: "",
      oweOrderNumber: "",
    });
    const loading = ref(false);
    const total = ref(0);
    const columns = [
      {
        title: "序号",
        align: "center",
        width: "80px",
        customRender: ({ index }) => {
          return index + 1;
        },
      },
      {
        title: "车场名称",
        align: "center",
        dataIndex: "parkingLotName",
      },
      {
        title: "车牌号",
        align: "outTime",
        dataIndex: "plateNumber",
      },
      {
        title: "进场时间",
        align: "center",
        dataIndex: "inTime",
      },
      {
        title: "离场时间",
        align: "center",
        dataIndex: "outTime",
      },
      {
        title: "应付金额（元）",
        align: "center",
        dataIndex: "shouldPayAmount",
        slots: { customRender: "shouldPayAmount" },
      },
      {
        title: "欠费金额（元）",
        align: "center",
        dataIndex: "oweAmount",
        slots: { customRender: "oweAmount" },
      },
    ];
    const loadData = async (page, pageSize) => {
      loading.value = true;
      try {
        let r2 = await detail(props.plateNumber, page, pageSize);
          oweRecordsVo.oweAmount = r2.data.oweAmount;
          oweRecordsVo.oweOrderNumber = r2.data.oweOrderNumber;
        items.oweRecords = r2.data.oweRecords;
        total.value = oweRecordsVo.oweOrderNumber;
        loading.value = false;
      } catch (e) {
        loading.value = false;
        await showErr(e);
      }
    };
    const money = m => {
          if ( m !== "undefined") {
              return (m / 100).toFixed(2);
          }
          return "";
      };
    const getparkingTime = t => {
          if (typeof t !== "undefined") {
              var h = ((t - (t % 3600)) / 3600).toFixed(0);
              var s = ((t % 3600) / 60).toFixed(0);
              return h == 0 ? s + "分钟" : h + "小时" + (s == 0 ? "" : s + "分钟");
          }
          return "";
      };

    onMounted(async () => {
      loadData(page.value, pageSize.value);
    });
    return {
      ...toRefs(props),
      disabled,
      visible,
      afterClose,
      items,
      page,
      pageSize,
      total,
      currentChange,
      doQuery,
      doRefresh,
      columns,
      loading,
      loadData,
      oweRecordsVo,
      money,
      getparkingTime,
    };
  },
};
</script>

<style scoped></style>
