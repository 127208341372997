import axios from "axios/index";

export const list = (plateNumber, nickName, phone, page, pageSize) => {
  return axios.get("/plateNumber/list", {
    params: {
      nickName,
      phone,
      plateNumber,
      page,
      pageSize,
    },
  });
};

export const detail = (plateNumber, page, pageSize) => {
  return axios.get(`/plateNumber/oweRecords`, {
    params: {
      plateNumber,
      page,
      pageSize,
    },
  });
};
