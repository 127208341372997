<template>
  <div ref="modalContainer"></div>
  <a-card class="content-wrapper">
    <template #title>
      <CardHeader></CardHeader>
    </template>
    <div class="body-content">
      <a-form layout="inline">
        <a-form-item label="车牌号">
          <a-input
            v-model:value="params.plateNumber"
            placeholder="输入车牌号"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item label="用户昵称">
          <a-input
            v-model:value="params.nickName"
            placeholder="输入用户昵称"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item label="手机号码">
          <a-input
            v-model:value="params.phone"
            placeholder="输入用户手机号"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="doQuery">
            <template #icon>
              <SearchOutlined />
            </template>
            查询
          </a-button>
        </a-form-item>
      </a-form>
      <div style="padding: 4px 0">
        <a-table
          :row-key="
            (record, index) => {
              return index;
            }
          "
          table-layout="fixed"
          :data-source="dataList.list"
          :columns="columns"
          :loading="loading"
          :pagination="false"
          bordered
          :scroll="{ y: 500 }"
        >
          <template #operation="{ record }">
            <TipButton
              perm="member:plateNumber:oweRecord"
              btn-class="btn-success"
              title="欠费记录"
              @btnClick="showDetailModal(record.plateNumber)"
            >
              <template #default>
                <ContainerOutlined />
              </template>
            </TipButton>
          </template>

          <template #plateNumber="{ record }">
            <a-tag :color="plateColorStyle(record.plateColor)">
              <span
                :style="{
                  color: record.plateColor == 4 ? '#001510' : '#FFFFFF',
                }"
                >{{ record.plateNumber }}</span
              ></a-tag
            >
          </template>
        </a-table>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:page-size="pageSize"
        :total="total"
        :show-total="total => `${total}条数据`"
        @change="currentChange"
      >
      </a-pagination>
    </div>
  </a-card>
  <detail
    v-if="detailShow"
    :plate-number="currentId"
    :modal-ref="$refs.modalContainer"
    @close="hideDetailShow"
  ></detail>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import usePlateNumber from "@/hooks/member/usePlateNumber";
import usePagination from "@/hooks/usePagination";
import { onMounted, ref } from "vue";
import Detail from "@/views/Member/PlateNumber/components/Detail";

import TipButton from "@/components/TipButton";
import useAddExit from "@/hooks/useAddExit";

export default {
  name: "index",
  components: {
    Detail,
    CardHeader,
    TipButton,
  },
  setup() {
    const { params, dataList, total, loading, columns, loadData } =
      usePlateNumber();
    const { page, pageSize, currentChange, doQuery, doRefresh } =
      usePagination(loadData);
    const detailShow = ref(false);
    const showDetailModal = plateNumber => {
      currentId.value = plateNumber;
      detailShow.value = true;
    };
    const hideDetailShow = () => {
      detailShow.value = false;
    };
    const { currentId } = useAddExit(doQuery);
    const plateColorStyle = val => {
      switch (val) {
            case 0:
                return "#888888";
            case 1:
                return "#0000FF";
            case 2:
                return "#FFA500";
            case 3:
                return "#000000";
            case 4:
                return "#FFFFFF";
            case 5:
                return "#008000";
            default:
                return "#FF0000";
        }
    };
    onMounted(() => {
      loadData(page.value, pageSize.value);
    });

    return {
      params,
      page,
      pageSize,
      total,
      loading,
      currentChange,
      dataList,
      doQuery,
      doRefresh,
      columns,
      currentId,
      detailShow,
      showDetailModal,
      hideDetailShow,
      plateColorStyle,
    };
  },
};
</script>

<style scoped></style>
